import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { white } from "./styles";

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site01: file(relativePath: { eq: "schwangere2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      site02: file(relativePath: { eq: "schwangere_home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mariacanders: file(relativePath: { eq: "maria_canders.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      startseite: file(relativePath: { eq: "haende.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      mariahauber: file(relativePath: { eq: "maria_hauber.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      annapaust: file(relativePath: { eq: "anna_paust.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      

    }

    
  `)
    
    if (props.image == "site01") return ( 
    <Img css={{height: "400px", border: "1px solid " + white}} 
    fluid={data.site01.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="0% 0%"
    alt=""/>
    )
    else if (props.image == "site02") return ( 
    <Img css={{height: "400px", border: "1px solid " + white}} 
    fluid={data.site02.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="0% 0%"
    alt=""/>
    )
    else if (props.image == "startseite") return ( 
    <Img css={{height: "auto", border: "1px solid " + white}} 
    fluid={data.startseite.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="35.4% 0%"
    alt=""/>
    )
    else if (props.image == "Maria Canders") return ( 
    <Img css={{height: "400px", border: "1px solid " + white}} 
    fluid={data.site01.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="11.8% 0%"
    alt=""/>
    )
    else if (props.image == "Maria Katrin Hauber") return ( 
    <Img css={{height: "400px", border: "1px solid " + white}} 
    fluid={data.site01.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="64.7% 0%"
    alt=""/>
    )
    else if (props.image == "Anna Paust") return ( 
    <Img css={{height: "400px", border: "1px solid " + white}} 
    fluid={data.site01.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="70.5% 0%"
    alt=""/>
    )
    else if (props.image == "leistungen") return ( 
    <Img css={{height: "400px", border: "1px solid " + white}} 
    fluid={data.site01.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="23.6% 0%"
    alt=""/>
    )
    else if (props.image == "kurse") return ( 
    <Img css={{height: "400px", border: "1px solid " + white}} 
    fluid={data.site01.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="29.4% 0%"
    alt=""/>
    )
    else if (props.image == "philosophie") return ( 
    <Img css={{height: "400px", border: "1px solid " + white}} 
    fluid={data.site01.childImageSharp.fluid} 
    objectFit="cover"
    objectPosition="41.3% 0%"
    alt=""/>
    )
    else if (props.image == "kontakt") return (
      <Img css={{height: "400px", border: "1px solid " + white}} 
      fluid={data.site01.childImageSharp.fluid} 
      objectFit="cover"
      objectPosition="47.2% 0%"
      alt=""/>
    )
    else if (props.image == "datenschutz") return (
      <Img css={{height: "400px", border: "1px solid " + white}} 
      fluid={data.site01.childImageSharp.fluid} 
      objectFit="cover"
      objectPosition="82.2% 0%"
      alt=""/>
    )
    else return (
      <Img css={{height: "400px", border: "1px solid " + white}} 
      fluid={data.site01.childImageSharp.fluid} 
      objectFit="cover"
      objectPosition="76.4% 0%"
      alt=""/>
    )
}


export default Image;