import React, { useState, useEffect } from 'react';
import { white,  FlexBox, dark, Button, darkred, orange, lightorange, } from './styles'
import {motion, AnimatePresence } from 'framer-motion';
import facepaint from 'facepaint';
import { Link } from 'gatsby';
const breakpoints = [576, 820, 992, 1200]

const mq = facepaint(
    breakpoints.map(bp => `@media (min-width: ${bp}px)`)
  )
const cookiebanner = {
    initial: {bottom: "-10vh", opacity: 0},
    animate: {bottom: "4vh", opacity: 1, transition: {delay: 2, ease: "easeOut"}},
    exit: {bottom: "-10vh", opacity: 0, transition: {ease: "easeOut"}}

}

const ConsentCookie = () => {
    const [cookieState, setCookieState] = useState("open")

    useEffect(() =>{
        let cname="accept"
        window.dataLayer = window.dataLayer || [];
        let cookie;
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
             cookie =  c.substring(name.length, c.length)
             if (cookie == "true"){
                 setCookieState("true")
                window.dataLayer.push({
                    'event': "consent_given",
                    'consent': "true",
                    });
            
            }
            else if (cookie == "false") {
                setCookieState("false")
                window.dataLayer.push({
                    'event': "consent_given",
                    'consent': "false",
                    });
                }
            else {setCookieState("open")}
            }
        }
 
        
        }
    )

    const accept = (cvalue) => {
        var cname ="accept"
        var d = new Date();
        var exdays= 90
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        if (cvalue == "true") {
            window.dataLayer.push({
                'event': "consent_given",
                'consent': "true",
                });
        }
        else if (cvalue == "false") {
            window.dataLayer.push({
                'event': "consent_given",
                'consent': "false",
                });
        }
        setCookieState(cvalue)

    }
    return (
        <>
        <AnimatePresence> 
            {(cookieState == "open") &&
            <motion.div initial="initial" animate="animate" exit="exit" variants={cookiebanner} css={{position: "fixed", width: "80vw", height: "auto", left: "10%",  background: white, zIndex: 7, padding: "1em 1em", border: "2px solid " + darkred, background: orange }}>
               <div css={mq({display: "flex", flexDirection: ["column", "column", "row", "row"], alignItems: ["flex-start", "flex-start", "center", "center"]})} > 
                <p css={{marginBottom: "1.2em !important " }, [mq({maxWidth: ["100%", "100%", "50%", "50%"], marginBottom: [0,0,0,0], fontSize: ["0.7em","0.7em","0.8em","0.8em"]})]}>Wir setzen auf dieser Webseite ausschließlich Cookies ein, die für das Betreiben der Seite notwendig sind. Nähere Hinweise erhalten Sie in unserer Datenschutzerklärung. </p>
                
                <Link to="/datenschutz">
                    <Button css={{fontSize: "0.7em !important", margin: "0.4em 0.4em", padding: "0.4em 1.2em"}}> Zur Datenschutzerklärung</Button>
                </Link>
                    <Button onClick={() => accept("true")} css={{fontSize: "0.7em !important", margin: "0.4em 0.4em", padding: "0.4em 1.2em"}}>Ich stimme zu</Button>
                </div>
            </motion.div>
            }
        </AnimatePresence>
        </>
    )
}

export default ConsentCookie;
