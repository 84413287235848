import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import {css} from "@emotion/core";
import styled from "@emotion/styled";
import React from "react"
import {orange, darkred, white, mq} from "./styles";

const H1 = styled.h1({
fontSize: "16px",
fontStyle: "italic",
marginTop: "70px",
marginLeft: "20px"

})
const Header = ({ siteTitle }) =>{
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    
    }
  `);
  return (
  <header
    style={{
      background: orange,
      zIndex: 10,
      width: "100vw",
      height: "100px",
      position: "relative",
      borderBottom: "1px solid " + white,
      marginBottom: 0,
    }}
  >
    <div css={{
      position: "relative",
      maxWidth: "1400px", 
      marginLeft: "auto", 
      marginRight: "auto",
      paddingLeft: "2.2em",
      paddingRight: "2.2em",
      display: "flex",
      height: "100%",
      flexDirection: "row",
      justifyContent: "flex-start",}}> 
    <div css={{width: "150px", height: "100%", background: darkred, borderLeft: "1px solid "+ white, borderRight: "1px solid "+ white, padding: "0.7em", float: "left"}}>
      <Link to="/"> <Img fluid={data.logo.childImageSharp.fluid} /></Link>
    </div>
    
      <H1 >
        <Link
          to="/"
          css={mq({
            display: ["none", "block", "block", "block"],
            color: darkred,
            textDecoration: `none`,
          })}
        >
          {siteTitle}
        </Link>
      </H1>
      </div>
  </header>
)
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
