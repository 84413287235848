import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core';
import Image from "../components/image";
import Header from "./header"
import "./layout.css"
import { FlexContainer, orange, FlexBox, lightorange, white, SiteBar, Wrapper, mq, darkred } from "./styles"
import Nav, {NavMobile} from "./navigation"
import { motion } from "framer-motion";
import ConsentCookie from "./consent";

const navVariants = {
  initial: {opacity: 1, x: "100%", transition: { duration: 0.4, opacity: {delay: 0.5}}},
  animate: {opacity: 1, x: 0, transition: {ease: "easeOut", delay: 0.4 }}
}
const menuToggler1 ={
  open: { x:0, },
  closed: { x: -5 }
}
const menuToggler2 ={
  open: { x:0},
  closed: { x:5}
}
const menuToggler3 = {
  open: {x:0 },
  closed: {x: -5 }
}

const Toggler = styled.div(
  {
  position: "fixed",
  top: "50px",
  right: "50px",
  transform: "translate(50%, -50%)",
  cursor: "pointer",
  height: "2,2rem",
  width: "1.6rem",
  transition: "all 0.7s ease-out",
  justifyContent: "space-evenly", 
  flexDirection: "column",
  backgroundColor: "transparent",
  zIndex: 13,
  order: 2
  },
  mq({
      display: ["flex", "flex", "none", "none"],
  })
)

const height = "calc(100% - 100px)"

const Layout = ({ children, layoutImage }) => {
  const [collapse, setCollapse] = useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
const headerheight = "100px";
 
  return (
    <>
    <Header siteTitle={data.site.siteMetadata.title} />
    <Wrapper css={css`height: calc(100vh - ${headerheight});`}>
      <ConsentCookie />
      <FlexContainer
       direction="row"
       justify="flex-start"
       css={mq({background: lightorange, paddingLeft: [0,0,"2.2em", "2.2em"], paddingRight: [0,0,"2.2em", "2.2em"], height:"100%", position: "relative"})}
      >
        <SiteBar css={mq({flex:" 0 0 auto", display: ["none", "none", "block", "block"], })}>
          <motion.div initial={{opacity: 0.8}} animate={{opacity: 1}} transition={{duration: 0.4, ease: "easeIn"}}> 
            <Image image={layoutImage}css={{height: "600px"}} />
          </motion.div>
        </SiteBar>
          
        <main css={{flex: "1 1 auto", width: "70%", background: lightorange, height: "100%", overflowY: "auto", overflowX: "hidden", paddingBottom: "0em", display: "inline-block" }}>
          {children}
        </main>

        <FlexBox direction="column" justify="flex-end" css={mq({ width: "20%", position: "relative", background: orange, height: "100%", borderLeft: "1px solid " + white, borderRight: "1px solid " + white, 
          display: ["none", "none", "block", "block"]
          })}>
          <Nav site={data.site.siteMetadata.title} />
        </FlexBox>
        <motion.div animate={collapse ? "animate" : "initial"} initial="initial" variants={navVariants}  css={{position: "fixed", height: height, right: 0,top: "100px" }}> 
        <FlexBox direction="column" justify="flex-start" css={mq({ width: "100%", position: "relative", background: orange, height: "100%", borderLeft: "1px solid " + white, borderRight: "1px solid " + white, 
          display: ["block", "block", "none", "none"]
          })}>
            
          <NavMobile site={data.site.siteMetadata.title} />
        </FlexBox>
        </motion.div>
        <Toggler onClick={() => setCollapse(!collapse)} >
                <motion.div
                key="123"
                variants={menuToggler1}
                initial="open"
                animate={collapse ? "closed" : "open"}
                css={css`width: 100%; height: 4px; background: ${darkred}; position: relative; margin-top: 0; `}
                ></motion.div>
                <motion.div
                key="125"
                variants={menuToggler2}
                initial="open"
                animate={collapse ? "closed" : "open"}
                css={css`width: 100%; height: 4px; background: ${darkred}; position: relative; margin-top: 4.5px; `}
                ></motion.div>
                <motion.div
                key="128"
                variants={menuToggler3}
                initial="open"
                animate={collapse ? "closed" : "open"}
                css={css`width: 100%; height: 4px; background: ${darkred}; position: relative; margin-top: 4.5px; `}
                ></motion.div>
            </Toggler>
      </FlexContainer>
    </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
