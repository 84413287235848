import React from "react";
import {Link} from "gatsby"
import styled from "@emotion/styled"
import { white, darkred, mq } from "./styles";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const NavContainer = styled.div({
    height: "100%",
    marginTop: 0,
    width: "100%",
    padding: "0em",
    fontSize: "20px",
    display: "flex", 
    flexDirection: "column",
    justifyContent: "space-between"

})
const NavMenu = styled.div(mq({
    height: "70%",
    width: "100%",
    padding: "0.8em",
    fontSize: "20px",
    overflowX: "hidden",
    overflowY: ["scroll", "scroll", "hidden", "hidden"]

}))

const NavHeader = styled.h5({
    marginLeft: "0em", 
   marginBottom: "0.5em",
   fontSize:"0.8em",
   color: darkred, 
   cursor: "pointer"

})

const NavItem = styled.h6({
    marginLeft: "1em", 
   color: darkred,
   cursor: "pointer",
   transition: "all 0.4s ease-out",

})

const Divider = styled.div({
    height: "0.8px",
    width: "100%",
    background: white,
    marginTop: "0.8em",
    marginBottom: "0.8em",
    borderRadius: "50%"
})
let active = {
    color: '#fefefe',
    

}
const Nav = (props) => {
    const {data } = useQuery(gql`
    {
        teammitglieder{
            vorname
            nachname
            id
            teamseite
        }
    }
    `)
    function slugify(string) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\-]+/g, '') // Remove all non-word characters
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
      }
    return (
        <NavContainer >
            <NavMenu> 
                <NavHeader>Team</NavHeader>
                {
                    data && data.teammitglieder.map(value => {
                        if (value.teamseite === true) return <NavItem key={value.id}><Link activeStyle={active} to={"/team/" + slugify(value.vorname) + "-" + slugify(value.nachname)}>{value.vorname} {value.nachname}</Link></NavItem>
                        return null
                    })
                }
            <Divider />
                <NavHeader><Link activeStyle={active} to="/leistungen">Leistungen</Link></NavHeader>
            <Divider />
                <NavHeader><Link activeStyle={active} to="/kurse-und-anmeldung">Kurse & Anmeldungen</Link></NavHeader>
            <Divider />
                <NavHeader>
                    <Link activeStyle={active} to="/philosophie">
                        Philosophie
                    </Link>
                </NavHeader>
            <Divider />
                <NavHeader>
                    <Link activeStyle={active} to="/kontakt"> 
                        Kontakt
                    </Link>
                </NavHeader>
            <Divider />




            </NavMenu>

            <div css={{padding: "1em", background: darkred, height: "20%", display: "flex", flexDirection: "column", justify:"flex-end", align: "flex-start"}} > 
                <Link to="/impressum" css={{color: white, fontSize: "0.7em", [":hover"]: {textDecoration: "underline" }}}>
                    Impressum 
                </Link>
                <Link to="/datenschutz" css={{color: white, fontSize: "0.7em", [":hover"]: {textDecoration: "underline" }}}>
                    Datenschutzerklärung
                </Link>
            </div>
          </NavContainer>

    )



}
export const NavMobile = (props) => {
    const {data } = useQuery(gql`
    {
        teammitglieder{
            vorname
            nachname
            id
            teamseite
        }
    }
    `)
    function slugify(string) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')

        return string.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\-]+/g, '') // Remove all non-word characters
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
      }
    return (
        

        <NavContainer >
            <NavMenu> 
                <NavHeader>Team</NavHeader>
                {
                    data && data.teammitglieder.map(value => {
                        if (value.teamseite === true) return <NavItem key={value.id}><Link activeStyle={active} to={"/team/" + slugify(value.vorname) + "-" + slugify(value.nachname)}>{value.vorname} {value.nachname}</Link></NavItem>
                        return null
                    })
                }
            <Divider />
                <NavHeader><Link activeStyle={active} to="/leistungen">Leistungen</Link></NavHeader>
            <Divider />
                <NavHeader><Link activeStyle={active} to="/kurse-und-anmeldung">Kurse & Anmeldungen</Link></NavHeader>
            <Divider />
                <NavHeader>
                    <Link activeStyle={active} to="/philosophie">
                        Philosophie
                    </Link>
                </NavHeader>
            <Divider />
                <NavHeader>
                    <Link activeStyle={active} to="/kontakt"> 
                        Kontakt
                    </Link>
                </NavHeader>
            <Divider />




            </NavMenu>

            <div css={{padding: "1em", background: darkred, height: "25%", display: "flex", flexDirection: "column", justify:"flex-start", align: "flex-start"}} > 
                <Link to="/impressum" css={{color: white, fontSize: "0.7em", [":hover"]: {textDecoration: "underline" }}}>
                    Impressum 
                </Link>
                <Link to="/datenschutzerklaerung" css={{color: white, fontSize: "0.7em", [":hover"]: {textDecoration: "underline" }}}>
                    Datenschutzerklärung
                </Link>
            </div>
          </NavContainer>


    )



}
export default Nav;