import styled from '@emotion/styled'
import facepaint from 'facepaint';

export const lightorange = "#FFB754";
export const orange = "#F29400";
export const darkred = "#B31B34";
export const white = "#fefefe";
export const grey= "#efefef";




const breakpoints = [576, 860, 1024, 1200]

export const mq = facepaint(
    breakpoints.map(bp => `@media (min-width: ${bp}px)`)
  )


export const Wrapper = styled.div({
 width: "100vw",
 position: "relative",
 overflowY: "hidden",
 maxWidth: "1400px",
 marginLeft: "auto",
 marginRight: "auto",

 }
)
export const SiteWrapper = styled.div({
    position: "relative",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    

})

export const SiteBar = styled.div({
    height: "100%", 
    background: orange,
    borderLeft: "1px solid "+ white,
    borderRight: "1px solid "+ white,
    width: "150px",
    position: "relative"
})
export const SiteContent = styled.div({
    width: "100%",
    height: "auto", 
    padding: "1.6em",
    background: lightorange,
    fontSize: "16px",
    maxHeight: "auto",
    paddingBottom: "4em",
    ["p"]: 
        {fontSize: "0.9em",
        lineHeight: "1.6em"}
},

)
export const TeamSiteContent = styled.div(mq({
    width: ["100%", "100%", "60%", "60%"],
    height: "auto", 
    paddingLeft: "1.2em",
    paddingRight: "1.6em",
    paddingTop: "1.2em",
    background: lightorange,
    fontSize: "16px",
    float: 'left',
    maxHeight: "auto",
    paddingBottom: "4em",
    order: [2,2,1,1],
    ["p"]: 
        {fontSize: "0.9em",
        lineHeight: "1.6em"},
    ["h1"]: 
        {
            marginBottom: "1em"
        }
})

)
export const TeamSiedeBar = styled.div(mq({
    width: ["100%", "100%", "40%", "40%"],
    height: "auto", 
    padding: "1.2em",
    float: 'left',
    background:'#ffad3d',
    fontSize: "16px",
    maxHeight: "auto",
    paddingBottom: "4em",
    order: [1,1,2,2],
    ["p"]: 
        {fontSize: "0.9em",
        lineHeight: "1.6em"}
})

)

export const FlexContainer = styled.div({
    display: "flex",

},
props => ({flexDirection: props.direction}),
props => ({justifyContent: props.justify}),
props => ({alignItems: props.align})
)

export const FlexContainerRow = styled.div(mq({
    display: "flex",
    flexDirection: ["column", "column","row", "row"],
    justifyContent: "space-between",
    alignItems: "flex-start"
})
)

export const FlexBox = styled.div({
    display: "flex",

},
props => ({flexDirection: props.direction}),
props => ({justifyContent: props.justify}),
props => ({alignItems: props.align})
)

export const Divider = styled.div({
    height: "0.8px",
    width: "100%",
    background: white,
    marginTop: "0.8em",
    marginBottom: "0.8em",
    borderRadius: "50%"

})

export const Button = styled.button({
    background: darkred,
    color: white,
    fontWeight: 600,
    border: "1px solid " + white,
    cursor: "pointer", 
    borderRadius: "1em",
    transition: "background 0.2s ease-in",
    [":hover"]: {
        background: orange
    }
})
export const GreyButton = styled.button({

    background: grey,
    border: "1px solid " + grey,
    cursor: "normal", 
    borderRadius: "1em"
})